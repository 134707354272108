import {tr} from "../../vue-components/helpers/i18n.js";

export default class LicenseHelper {
    static getCategories() {
        return [{
            value: `digital-signage`,
            text: tr(`Digital Signage|Affichage digital`),
            glyph: `fa-airplay`,
            itemTypes: [
                {value: `player`, text: `Player|Diffuseur`}
            ]
        }, {
            value: `wifi`,
            text: `Wi-Fi`,
            glyph: `fa-wifi`,
            itemTypes: [
                {value: `router`, text: `Controller|Contrôleur`}
            ]
        }];
    }

    static getCategoryByValue(categoryValue) {
        return this.getCategories().find(c => c.value === categoryValue);
    }

    static getCategoryItems(categoryValue) {
        const category = this.getCategoryByValue(categoryValue);
        if (!category) {
            return [];
        }
        return category.itemTypes;
    }

    static getItemTypes() {
        return [{
            category: `digital-signage`,
            value: `player`,
            text: `Player|Diffuseur`,
            slotName: `Screens|Écrans`,
            glyph: `fa-tv`,
            icon: getPublicIcon(`player`)
        },
            {
            category: `wifi`,
            value: `router`,
            text: `Controller|Contrôleur`,
            slotName: `Network equipment|Équipements réseau`,
            glyph: `fa-router`,
            icon: getPublicIcon(`router`)
        }];
    }

    static getItemType(itemType) {
        return this.getItemTypes().find(t => t.value === itemType);
    }

    static getSlotText(itemType) {
        const typeDetails = this.getItemType(itemType);
        if (typeDetails) {
            return typeDetails.slotName;
        } else {
            return `Slots`;
        }
    }

    static getItemTypeText(itemType) {
        const typeDetails = this.getItemType(itemType);
        if (typeDetails) {
            return tr(typeDetails.text);
        } else {
            return itemType;
        }
    }
}
