import store from '../../src/store'

export default class DealerHelper {
    static getDealer(dealerid) {
        return store.state.dealers.find(dealer => {
            return dealer.dealerid === dealerid;
        });
    }

    static getDealerChildren(dealerid) {
        if (!this.dealersChildren) {
            this.dealersChildren = {};
        }
        if (this.dealersChildren.hasOwnProperty(dealerid.toLowerCase())) {
            return this.dealersChildren[dealerid.toLowerCase()];
        }
        let result = [];

        const children = store.state.dealers.filter(dealer => dealer.parent.toLowerCase() === dealerid.toLowerCase());
        for (let childDealer of children) {
            result.push(childDealer.dealerid.toLowerCase());
            result = result.concat(this.getDealerChildren(childDealer.dealerid.toLowerCase()));
        }

        this.dealersChildren[dealerid.toLowerCase()] = result;
        return result;
    }
}
