<template>
    <div id="app" :class="{ 'embed': isEmbed }">
        <app-menu v-if="!isEmbed"></app-menu>
        <keep-alive>
            <router-view :key="$route.fullPath"/>
        </keep-alive>
    </div>
</template>

<script>
import Network from "../vue-components/helpers/Network.js";
import AppMenu from "@/layout/app-menu.vue";

export default {
    components: {AppMenu},
    computed: {
        isEmbed() {
            return this.$route.query.embed === `true`;
        },
    },
    mounted() {
        let token = new URLSearchParams(window.location.search).get(`token`);
        if (token) {
            Network.setToken(token);
            window.history.pushState({}, document.title, `/`);
        }
    }
}
</script>

<style lang="scss">
@import "assets/style/global.scss";

  #app {
    //font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
