<template>
    <div class="app-page" :class="{ 'fullscreen': fullscreen}">
        <slot name="page-header"></slot>
        <component :is="noContainer ? 'div' : 'b-container'" class="app-content">
            <slot></slot>
        </component>
    </div>
</template>

<script>

export default {
    name: `app-page`,
    props: {
        fullscreen: {type: Boolean},
        noContainer: {type: Boolean}
    }
}
</script>

<style lang="scss" scoped>
.app-page {
    height: 100vh;
    display: flex;
    flex-direction: column;

    .app-content {
        flex: 1 1 auto;
        overflow-y: auto;
        min-height: 0px;
    }

    @media(min-width: 576px) {
        padding-left: 250px;
    }
}

#app.embed {
    .app-page {
        padding-left: 0 !important;
    }
}
</style>
