<template>
    <app-page>
        <template #page-header>
            <page-header back-button="/packages" :name="mode !== `edit` ? `New Package|Nouveau Package` : (licensePackage ? licensePackage.name : `-`)|tr"
                         no-search no-secondary-bar>
                <template #search>
                    <div v-if="canWrite(Permission.LICENSE.feature.MANAGEMENT)">
                        <b-btn @click="savePackage" variant="success" class="ml-2" :disabled="!formValid">
                            <template v-if="mode === `edit`">
                                <i class="fas fa-save mr-1"></i>
                                <span v-tr>Save|Enregistrer</span>
                            </template>
                            <template v-else>
                                <i class="fas fa-plus mr-1"></i>
                                <span v-tr>Create|Créer</span>
                            </template>
                        </b-btn>
                    </div>
                </template>
            </page-header>
        </template>

<!--        <h2 class="mt-3" v-tr>Package|Package</h2>-->

        <b-row>
            <b-col cols="12" md="6" lg="4">
                <b-card class="my-3" no-body>
                    <b-card-body class="package" v-if="licensePackage">
                        <h5 class="mb-3">
                            <span v-tr>About Package|À propos du package</span>
                            <i class="fas fa-info-circle fa-main-icon"/>
                        </h5>

                        <e-translation-input :label="'Name|Nom'|tr" v-model="licensePackage.name" maxlength="50" :allow-empty="false"/>

                        <e-translation-input :label="'Description|Description'|tr" v-model="licensePackage.description" type="textarea" :allow-empty="false"/>

                        <b-form-group :label="'Category|Catégorie'|tr">
                            <b-input-group>
                                <b-input-group-text>
                                    <i class="fas fa-fw" :class="LicenseHelper.getCategoryByValue(licensePackage.category).glyph"></i>
                                </b-input-group-text>
                                <b-select v-model="licensePackage.category" @change="categoryChanged">
                                    <option v-for="category in LicenseHelper.getCategories()" :value="category.value">{{ category.text }}</option>
                                </b-select>
                            </b-input-group>
                        </b-form-group>
                        <b-form-group :label="'License type|Type de licence'|tr" v-if="licensePackage.category">
                            <b-input-group>
                                <b-input-group-text>
                                    <i class="fas fa-fw" :class="LicenseHelper.getItemType(licensePackage.license_type).glyph"></i>
                                </b-input-group-text>
                                <b-select v-model="licensePackage.license_type" @change="itemTypeChanged">
                                    <option v-for="licenseType in LicenseHelper.getCategoryItems(licensePackage.category)" :value="licenseType.value">{{ licenseType.text|tr }}</option>
                                </b-select>
                            </b-input-group>
                        </b-form-group>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" lg="4">
                <b-card class="my-3" no-body>
                    <b-card-body class="package" v-if="licensePackage">
                        <h5 class="mb-3">
                            <span v-tr>Package Content|Contenu du package</span>
                            <i class="fas fa-box fa-main-icon"/>
                        </h5>

                        <b-form-group :label="'License duration|Durée de la licence'|tr">
                            <b-input-group :append="'Months|Mois'|tr">
                                <b-input type="number" v-model.number="licensePackage.license_months_duration" min="1"/>
                            </b-input-group>
                        </b-form-group>

                        <hr>

                        <e-translation-input :label="'Slots name|Nom des slots'|tr" v-model="licensePackage.slot_name"></e-translation-input>

                        <b-form-group :label="'Slots count|Nombre de slots'|tr">
                            <b-input v-model.number="licensePackage.default_slots" type="number" min="0" required></b-input>
                        </b-form-group>
                    </b-card-body>
                </b-card>
            </b-col>
            <b-col cols="12" md="6" lg="4">
                <b-card class="my-3" no-body>
                    <b-card-body class="package" v-if="licensePackage">
                        <h5 class="mb-3">
                            <span v-tr>Pricing|Prix</span>
                            <i class="fas fa-money-bill fa-main-icon"/>
                        </h5>

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group :label="'Price per cycle|Prix par cycle'|tr">
                                    <e-money-input v-model="licensePackage.price_per_cycle" required></e-money-input>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group :label="'Billing cycle|Cycle de facturation'|tr">
                                    <b-select v-model="licensePackage.billing_cycle">
                                        <option value="weekly" v-tr>Weekly|Hebdomadaire</option>
                                        <option value="monthly" v-tr>Monthly|Mensuel</option>
                                        <option value="quarterly" v-tr>Quarterly|Trimestriel</option>
                                        <option value="yearly" v-tr>Yearly|Annuel</option>
                                    </b-select>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <hr class="mt-0">

                        <b-row>
                            <b-col cols="12" md="6">
                                <b-form-group :label="'Default discount|Réduction par défaut'|tr">
                                    <b-input-group append="%">
                                        <b-input type="number" v-model.number="licensePackage.default_discount"></b-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group :label="'Default discount duration|Durée de la réduction'|tr">
                                    <b-input-group :append="'Months|Mois'|tr">
                                        <b-input type="number" v-model.number="licensePackage.default_discount_months_duration" min="0"/>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>

        <div class="text-right" v-if="canWrite(Permission.LICENSE.feature.MANAGEMENT)">
            <e-button-confirm @confirmed="deletePackage" variant="outline-danger" v-if="canAdminEyeInWireless() && mode === `edit`">
                <i class="fas fa-trash mr-1"></i>
                <span v-tr>Delete|Supprimer</span>
            </e-button-confirm>
            <b-btn @click="savePackage" variant="success" class="ml-2" :disabled="!formValid">
                <template v-if="mode === `edit`">
                    <i class="fas fa-save mr-1"></i>
                    <span v-tr>Save|Enregistrer</span>
                </template>
                <template v-else>
                    <i class="fas fa-plus mr-1"></i>
                    <span v-tr>Create|Créer</span>
                </template>
            </b-btn>
        </div>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import ETranslationInput from "../../vue-components/components/e-translation-input.vue";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import EMoneyInput from "../../vue-components/components/e-money-input.vue";
import LicenseHelper from "@/helpers/LicenseHelper.js";

export default {
    name: `package`,
    components: {EMoneyInput, EButtonConfirm, ETranslationInput},
    data() {
        return {
            licensePackage: null,
            mode: `edit`,
            LicenseHelper
        }
    },
    activated() {
        this.mode = this.$route.params.id === `create` ? `create` : `edit`;
        this.getPackage();
    },
    computed: {
        icon() {
            if (!this.licensePackage) {
                return getPublicIcon(`player`);
            }

            switch (this.licensePackage.item_type) {
                case `player`:
                default:
                    return getPublicIcon(`player`);
            }
        },
        emptyIcon() {
            if (!this.licensePackage) {
                return getPublicIcon(`player-offline`);
            }

            switch (this.licensePackage.item_type) {
                case `player`:
                default:
                    return getPublicIcon(`player-offline`);
            }
        },
        formValid() {
            if (!this.licensePackage) {
                return `-`
            }

            return this.licensePackage.name
        }
    },
    methods: {
        getPackage() {
            if (this.mode === `create`) {
                this.licensePackage = {
                    category: `digital-signage`,
                    license_type: `player`,
                    license_months_duration: 12,
                    price_per_cycle: 2999,
                    default_slots: 4,
                    billing_cycle: `monthly`,
                    default_discount: 0,
                    default_discount_months_duration: 0,
                    slot_name: LicenseHelper.getSlotText(`player`)
                }
            } else {
                Network.get(`/api/package/${this.$route.params.id}`)
                    .then(resp => {
                        this.licensePackage = resp.data;
                    })
            }
        },
        savePackage() {
            if (this.mode === `create`) {
                Network.post(`/api/package/create`, this.licensePackage)
                    .then(resp => {
                        this.showSavedMessage(`Package created|Package créée`);
                        this.$router.push(`/package/${resp.data.id}`);
                    })
            } else {
                Network.patch(`/api/package/edit`, this.licensePackage)
                    .then(() => {
                        this.showSavedMessage(`Package saved|Package enregistrée`);
                        this.getPackage();
                    })
            }
        },
        deletePackage() {
            Network.delete(`/api/package/${this.licensePackage.id}`)
                .then(() => {
                    this.showSavedMessage(`Package deleted|Package supprimée`);
                    this.$router.push(`/packages`);
                })
        },
        categoryChanged() {
            this.licensePackage.license_type = LicenseHelper.getCategoryItems(this.licensePackage.category).at(0).value;
            this.itemTypeChanged();
        },
        itemTypeChanged() {
            this.licensePackage.slot_name = LicenseHelper.getSlotText(this.licensePackage.license_type);
        }
    }
}
</script>

<style lang="scss" scoped>
.package {
    text-align: left;
}
</style>
