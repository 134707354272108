import Vue from 'vue';
import Vuex from 'vuex';
import Network from "../vue-components/helpers/Network.js";
import env from "./env.mjs"

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        user: null,
        dealerid: null,
        dealers: []
    },
    getters: {
        isAuthenticated(state) {
            return !!state.user;
        }
    },
    mutations: {
        authSuccess(state, user) {
            state.user = user;
        },
        loggedOut(state) {
            state.user = null;
        },
        setDealerid(state, dealerid) {
            state.dealerid = dealerid;
            localStorage.setItem(`currentDealerFilter`, dealerid);
            localStorage.setItem(`currentDealerFilterText`, dealerid);
        },
        setDealers(state, dealers) {
            state.dealers = dealers;
        }
    },
    actions: {
        getCurrentUser({commit, dispatch}) {
            return new Promise((resolve, reject) => {
                let token = localStorage.getItem(`token`);

                if (!token) {
                    const currentURL = new URL(document.URL);
                    const currentURLParams = new URLSearchParams(currentURL.search);

                    if (currentURLParams.has(`token`)) {
                        token = currentURLParams.get(`token`);
                    }
                }

                if (token) {
                    Network.setToken(token);

                    Network.get(`/users/current-user`, null, {baseUrl: window.env.VUE_APP_ENV === `local` ? env.dev.VUE_APP_SYSTEM_API_URL : window.env.VUE_APP_SYSTEM_API_URL})
                        .then(response => {
                            const user = response.data;
                            if (user) {
                                commit(`authSuccess`, user);
                                let dealerid = user.dealerid;
                                const localStorageDealerid = localStorage.getItem(`currentDealerFilter`);
                                if (localStorageDealerid) {
                                    dealerid = localStorageDealerid;
                                }
                                commit(`setDealerid`, dealerid);
                                dispatch(`getDealers`);
                                resolve(user);
                            }
                        })
                        .catch(err => {
                            /*
                            To avoid being disconnected when server restart when working locally
                             */
                            if (window.env.VUE_APP_ENV !== `local`) {
                                localStorage.removeItem(`token`);
                                Network.removeToken();
                            }
                            reject(err);
                        });
                } else {
                    resolve(false);
                }
            });
        },
        getDealers({commit}) {
            return new Promise(resolve => {
                Network.get(`/dealers/all-readable-dealers`, null, {baseUrl: window.env.VUE_APP_ENV === `local` ? env.dev.VUE_APP_SYSTEM_API_URL : window.env.VUE_APP_SYSTEM_API_URL})
                    .then(response => {
                        commit(`setDealers`, response.data);
                        resolve();
                    });
            });
        },
        logout({commit}, payload = {}) {
            commit(`loggedOut`);
            localStorage.removeItem(`token`);
            Network.removeToken();

            const redirectURL = new URL(window.env.VUE_APP_LOGIN_URL);
            redirectURL.searchParams.append(`redirect_url`, window.location.href);
            if (payload.forceLogout) {
                redirectURL.searchParams.append(`logout`, `1`);
            }

            window.location.href = redirectURL.toString();
        }
    },
    modules: {}
});
