<template>
    <div class="page-header">
        <b-navbar>
            <component :is="noContainer ? 'div' : 'b-container'">
                <h3 class="mb-0" v-if="link">
                    <router-link :to="link">{{ name }}</router-link>
                </h3>
                <h3 class="mb-0" v-else>
                    <b-btn :to="backButton" v-if="backButton" variant="light" class="mr-2"><i class="fas fa-arrow-left"/></b-btn>
                    {{ name }}
                </h3>
                <div v-if="!noSearch" class="search ml-auto input-container">
                    <i class="far fa-search fa-input-left"></i>
                    <e-input state="disabled" :debounce-time="300" @debounce="updateSearch" ref="mainSearch" v-model="search" autocomplete="off" :placeholder="'Search...|Chercher...'|tr"></e-input>
                </div>
                <slot name="search"></slot>
            </component>
        </b-navbar>
        <b-navbar v-if="!noSecondaryBar">
            <component :is="noContainer ? 'div' : 'b-container'" class="d-flex w-100 justify-content-end">
                <div v-if="$slots.aside" class="aside">
                    <slot name="aside"></slot>
                </div>
                <div class="actions">
                    <slot></slot>
                </div>
            </component>
        </b-navbar>
    </div>
</template>

<script>

import EInput from "../../vue-components/components/e-input.vue";

export default {
    name: `page-header`,
    components: {EInput},
    props: {
        value: {type: String},// for search
        backButton: {type: String},
        name: {type: String},
        loading: {type: Boolean},
        noSearch: {type: Boolean},
        noSecondaryBar: {type: Boolean},
        link: {type: String},
        noContainer: {type: Boolean}
    },
    data() {
        return {
            search: ``
        }
    },
    methods: {
        updateSearch(value) {
            this.$emit(`input`, value);
        }
    }
}
</script>

<style lang="scss" scoped>
.page-header {
    border-bottom: 1px solid #e1e3e6;
    padding-top: 10px;
    padding-bottom: 10px;
    position: sticky;
    top: 0;
    z-index: 10;
    background: white;

    @media(max-width: 576px) {
        h3 {
            font-size: 16pt;
            text-align: left;
        }
    }

    @media(min-width: 576px) {
        .container {
            padding-right: 15px;
            padding-left: 15px;
        }
    }

    .aside {
        text-align: left;
        flex: 1;
        margin-right: 10px;
    }

    .search {
        .input-group-text {
            background: white;
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .form-control {
            border-color: #e1e3e6;
        }
    }
}
</style>
