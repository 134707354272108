<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body>
                <b-card-body>
                    <h4 v-tr>Service & Fees</h4>
                    <p><i class="fas fa-check mr-1"/> Customer wishes to subscribe to EYE-IN MEDIA software to manage <b>{{ contractData.contract.number_players }} screens</b> in their establishment.</p>
<!--                    <p><i class="fas fa-check mr-1"/> Customer agrees to pay a one-time initial subscription activation fee of ${{ contractData.contract.one_time_activation_fee }}</p>-->
                    <p><i class="fas fa-check mr-1"/> Customer agrees to pay a monthly fee of <b>${{ contractData.contract.monthly_payment_per_screen}} per screen</b> billed annualy in advance.</p>
                    <p><i class="fas fa-check mr-1"/> Customer agrees to pay ${{ contractData.contract.hourly_service_fee}} per hour for any services related to content creation, adaptation, conversion, or any content management or consulting services performed by Eye-In Media at the request of Customer.</p>
                    <p><i class="fas fa-check mr-1"/> All charges will be processed through pre-authorized ACH debits or credit card payments. A service fee of {{ contractData.contract.credit_card_fee_percent }}% will be charged to Customer.</p>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name: `digital-signage-contract-overview`,
    props: {
        contract: {type: Object, required: true}
    },
    computed: {
        contractData() {
            return this.contract.contract_data;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
