<template>
    <component :is="currentElementTag" href="#" @click.prevent="askForConfirmation" :class="{ 'btn-confirm' : !dropdownItem }" :variant="variant" :size="size" :block="block" :disabled="disabled">
        <template  v-if="dropdownItem">
            <a class="dropdown-item" :class="dropdownClass">
                <slot></slot>
            </a>
        </template>
        <template v-else>
            <slot></slot>
        </template>
    </component>
</template>

<script>
    export default {
        name: `e-button-confirm`,
        props: {
            elementTag: { type: String, default: `b-btn` },
            confirmText: { type: String },
            confirmTitle: { type: String },
            icon: { type: String, default: `warning` },
            buttonText: { type: String, default: `OK` },
            buttonClass: { type: String, default: `` },
            variant: { type: String },
            size: { type: String },
            disabled: { type: Boolean },
            dropdownItem: { type: Boolean },
            dropdownClass: { type: String, default: `` },
            block: { type: Boolean }
        },
        computed: {
            currentElementTag() {
                if (this.dropdownItem) {
                    return `li`;
                } else {
                    return this.elementTag;
                }
            }
        },
        methods: {
            askForConfirmation() {
                this.$swal({
                    title: this.tr(this.confirmTitle) || this.tr(`Confirm|Confirmer`),
                    text: this.tr(this.confirmText) || this.tr(`Are you sure?|Êtes-vous sûr ?`),
                    icon: this.icon || `warning`,
                    buttons: {
                        cancel: this.tr(`Cancel|Annuler`),
                        confirm: {
                            text: this.tr(this.buttonText),
                            className: this.buttonClass
                        }
                    }
                }).then(value => {
                    if (value) {
                        this.$emit(`confirm`, true);
                        this.$emit(`confirmed`, true);
                    }
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-confirm {
        display: inline-block;
    }
</style>
