<template>
    <b-btn-group class="browser-picker" :class="{'inline': inline, 'no-icon': noIcon}">
        <b-dropdown ref="dropdown" v-if="items" :variant="variant"  style="flex: 10;">
            <template #button-content>
                <template v-if="noIcon">
                    <div v-if="itemSelected">{{itemSelected.name|tr}}</div>
                    <div v-else>{{ emptyLabel|tr }}</div>
                </template>
                <template v-else>
                    <e-browser-item v-if="itemSelected" :item="itemSelected" :icon="icon" view="list" theme="light"
                                    :translate="translate" :standalone="true"></e-browser-item>
                    <e-browser-item v-else :item="{ id: null, name: tr(emptyLabel), placeholder: true }" :icon="icon"
                                    view="list" theme="light" :translate="translate" :standalone="true"></e-browser-item>
                </template>
            </template>

            <e-browser :translucent="false" :items="itemsFiltered" v-model="itemSelected" :no-footer="true" no-options
                       :icon="icon" forced-view="list" theme="light" :translate="translate" scrollable
                       :search-autofocus="true"></e-browser>
        </b-dropdown>

        <b-btn v-if="allowEmpty" @click="itemSelected = null" :disabled="!itemSelected" :variant="variant" style="max-width: 30px;"
            :title="'Unselect item|Déselectionner l\'élément'|tr">
            <i class="fas fa-times"></i>
        </b-btn>

        <slot></slot>
    </b-btn-group>
</template>

<script>
    export default {
        name: `e-browser-picker`,
        props: {
            value: { type: [Object, String, Number] },
            variant: { type: String, default: `light` },
            returnEntireObject: { type: Boolean, default: false },
            label: { type: String },
            items: { type: [Array, Function] },
            inline: { type: Boolean, default: false },
            emptyLabel: { type: String, default: `Nothing selected...|Aucune sélection...` },
            allowEmpty: { type: Boolean, default: false },
            icon: { type: [String, Function] },
            noIcon: { type: Boolean, default: false },
            translate: { type: Boolean, default: true }
        },
        data() {
            return {
                loading: true,
                extended: false,
                search: ``,
                selection: null
            }
        },
        computed: {
            itemSelected: {
                get() {
                    return this.selection;
                },
                set(value) {
                    this.selection = value;
                    this.$emit(`update:selection`, value);
                }
            },
            itemsFiltered() {
                return this.items.filter(i => {
                    return !i.deleted && !i.archived;
                })
            }
        },
        mounted() {
            this.refresh();
        },
        methods: {
            refresh() {
                if (this.value && this.items) {
                    if (this.returnEntireObject) {
                        this.itemSelected = this.items.find(i => {
                            return i.id === this.value.id;
                        });
                    } else {
                        this.itemSelected = this.items.find(i => {
                            return i.id === this.value;
                        });
                    }
                } else {
                    this.itemSelected = null;
                }
            }
        },
        watch: {
            value() {
                this.refresh();
            },
            itemSelected() {
                if (this.itemSelected) {
                    if (this.returnEntireObject) {
                        this.$emit(`input`, this.itemSelected);
                    } else {
                        this.$emit(`input`, this.itemSelected.id);
                    }
                    if (this.$refs.dropdown) {
                        this.$refs.dropdown.hide();
                    }
                } else {
                    this.itemSelected = null;
                    this.$emit(`input`, null);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .browser-picker {
        width: 100%;
        height: 48px;
        text-align: left;

        ::v-deep {
            .btn.dropdown-toggle {
                padding: 4px 0 0 4px;
                letter-spacing: 0;
                text-align: left;
                //height: 40px;

                &:after {
                    right: 7px;
                    position: absolute;
                    top: 24px;
                }

                .browser-item {
                    border: none;
                    pointer-events: none;

                    .fields {
                        left: 0;
                    }
                }
            }

            .browser {
                background: transparent !important;

                .browser-header {
                    padding: 7px;

                    .search {
                        position: relative;
                        right: 0;
                        top: 0;
                        float: none;
                    }
                }

                .browser-content {
                    padding: 7px;
                    max-height: 80vh;
                }

                h4 {
                    margin-bottom: 10px;
                }
            }

            .dropdown-menu {
                padding: 0;
            }
        }

        &.no-icon {
            padding: 0;
            height: auto;

            ::v-deep {
                .btn {
                    padding: 5px 10px;
                    text-align: left;

                    &:after {
                        top: 17px;
                    }
                }
            }

            &.inline ::v-deep  {
                .btn {
                    padding-left: 10px !important;
                }
            }
        }

        &.inline ::v-deep  {
            min-width: 250px;
            width: auto;
            display: inline-flex !important;

            .btn {
                padding: 0 !important;
                width: 100%;

                ::v-deep {
                    .icon {
                        height: 24px;
                        width: 24px;
                        margin: 3px 0;
                    }

                    .browser-item {
                        height: 100%;

                        ::v-deep {
                            .fields {
                                left: 32px;

                                .name {
                                    height: 100%;
                                    width: 100%;
                                    position: relative;
                                    left: 0;
                                }
                            }
                        }
                    }
                }

                &:after {
                    top: 14px;
                }
            }
        }
    }

    .input-group {
        .browser-picker.inline:not(:first-child) {
            ::v-deep .btn {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0 !important;
            }
        }
        .browser-picker.inline:not(:last-child) {
            ::v-deep .btn {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0 !important;
            }
        }
    }
</style>
