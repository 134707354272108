import Vue from 'vue';
import App from './App.vue';
import router from './router.js';
import store from './store.js';
import "../vue-components/helpers/vue-mixin.js";

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import VueSwal from 'vue-swal'
import './vue-global';
import moment from 'moment';
import VueMoment from 'vue-moment'
import appPage from '/src/layout/app-page.vue'
import pageHeader from '/src/layout/page-header.vue'

import '../vue-components/helpers/vue-filters.js';

import {Drag, Drop} from 'vue-drag-drop';
import {VueContext} from 'vue-context';
import Vue2TouchEvents from 'vue2-touch-events';

import EBrowser from '../vue-components/components/e-browser'
import EBrowserItem from '../vue-components/components/e-browser-item'
import EBrowserPicker from '../vue-components/components/e-browser-picker'
import EBrowserIcon from '../vue-components/components/e-browser-icon'

import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueSwal);
Vue.use(VueMoment, {
    moment
});

Vue.component(`drag`, Drag);
Vue.component(`drop`, Drop);
Vue.component(`context-menu`, VueContext);
Vue.use(Vue2TouchEvents, {
    touchClass: ``,
    tapTolerance: 10,
    swipeTolerance: 30,
    longTapTimeInterval: 400
});

Vue.component(`app-page`, appPage);
Vue.component(`page-header`, pageHeader);

Vue.component(`e-browser`, EBrowser);
Vue.component(`e-browser-item`, EBrowserItem);
Vue.component(`e-browser-icon`, EBrowserIcon);
Vue.component(`e-browser-picker`, EBrowserPicker);

Vue.component(`vue-multiselect`, VueMultiselect);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount(`#app`);
