<template>
    <b-modal :visible="value" @shown="onShown" @hidden="onHide" :title="'Please Draw your Signature|Merci d\'apposer votre signature'|tr" scrollable no-close-on-esc no-close-on-backdrop body-class="p-0">
        <div ref="parent">
            <canvas ref="canvas"></canvas>
        </div>

        <div slot="modal-footer">
            <b-btn @click.stop="clear">
                <span v-tr>Clear|Effacer</span>
            </b-btn>

            <b-btn class="ml-2" @click.stop="save" variant="primary">
                <span v-tr>Submit|Valider</span>
            </b-btn>
        </div>
    </b-modal>
</template>

<script>
import SignaturePad from 'signature_pad';

export default {
    name: `e-signature`,
    props: {
        value: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            signature: null
        }
    },
    methods: {
        onShown() {
            this.$nextTick(() => {
                this.signature = new SignaturePad(this.$refs.canvas);
                this.$refs.canvas.width = this.$refs.parent.clientWidth;
                this.$refs.canvas.height = this.$refs.canvas.width / 2;
            });
        },
        onHide() {
            this.$emit(`input`, false);
        },
        clear() {
            if (this.signature)
                this.signature.clear();
        },
        save() {
            const base64 = this.signature.toDataURL(`image/png`);
            this.$emit(`sign`, base64);
            this.onHide();
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
