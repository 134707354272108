import Vue from "vue";
import {getMarketIcon, getMarketName} from "../vue-components/helpers/utils.js";
import store from "./store.js"

export function formatDealer(dealerid) {
    if (!dealerid) {
        return dealerid;
    }
    const dealer = store.getters.getDealer(dealerid);
    if (!dealer || !store.state.user) {
        return dealerid;
    }
    const dealerDisplayField = store.state.user.dealer_display_field;
    if (dealer[dealerDisplayField]) {
        return dealer[dealerDisplayField];
    } else {
        return dealerid;
    }
}

Vue.filter(`dealer`, formatDealer);

export function dealerAlias(dealerid) {
    if (!dealerid) {
        return dealerid;
    }
    const dealer = store.getters.getDealer(dealerid);
    if (!dealer || !store.state.user) {
        return dealerid;
    }
    return dealer.alias;
}

Vue.filter(`dealerAlias`, dealerAlias);

export function dealerCompanyName(dealerid) {
    if (!dealerid) {
        return dealerid;
    }
    const dealer = store.getters.getDealer(dealerid);
    if (!dealer || !store.state.user) {
        return dealerid;
    }
    return dealer.company_name;
}

Vue.filter(`dealerCompanyName`, dealerCompanyName);

export function dealerMarket(dealerid) {
    if (!dealerid) {
        return dealerid;
    }
    const dealer = store.getters.getDealer(dealerid);
    if (!dealer || !store.state.user) {
        return dealerid;
    }
    return dealer.market;
}

Vue.filter(`dealerMarket`, dealerMarket);

export function dealerMarketIcon(dealerid) {
    const market = dealerMarket(dealerid);
    if (market) {
        return getMarketIcon(market);
    } else {
        return null;
    }
}

Vue.filter(`dealerMarketIcon`, dealerMarketIcon);

export function marketName(market) {
    return getMarketName(market)
}

Vue.filter(`marketName`, marketName);

export default {};
