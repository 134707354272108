import Vue from "vue";
import PermissionHelper from "../vue-components/helpers/PermissionHelper";
import Permission from "../vue-components/helpers/permissions";
import "../vue-components/helpers/vue-filters";
import "../vue-components/helpers/vue-directives";
import "../vue-components/helpers/vue-mixin";
import "./vue-app-filters.js";

Vue.mixin({
    data() {
        return {
            Permission: Permission
        }
    },
    computed: {
        dealerid() {
            return this.$store.state.dealerid;
        },
        currentUser() {
            return this.$store.state.user;
        },
        userDealerDisplayField() {
            if (this.$store.state.user) {
                return this.$store.state.user.dealer_display_field;
            } else {
                return `dealerid`;
            }
        },
        dealerInfo() {
            const dealer = this.$store.state.dealers.find(dealer => {
                return dealer.dealerid === this.dealerid;
            });
            if (dealer) {
                return dealer;
            } else {
                return {};
            }
        },
        workspace() {
            return this.$store.state.workspace;
        }
    },
    methods: {
        isDealerParent() {
            return false;
        },
        isUserLevelAdmin() {
            return true;
        },
        isAccessRestricted() {
            return this.currentUser && (this.currentUser.access_restricted === 1 || this.currentUser.access_restricted === true);
        },
        canRead(permission) {
            return PermissionHelper.can(`read`, permission);
        },
        canWrite(permission) {
            return PermissionHelper.can(`write`, permission);
        },
        canAdmin(permission) {
            return PermissionHelper.can(`admin`, permission);
        },
        canAdminEyeInWireless() {
            return PermissionHelper.can(`admin`, null, `Eye-In Wireless`);
        },
        isEyeInWireless() {
            return this.dealerid.toLowerCase() === `eye-in wireless`;
        },
        startPrint() {
            this.$store.state.printMode = true;
            this.$forceUpdate();
            setTimeout(() => {
                window.print();
            }, 1000)
        },
        mixinRedirectToWelcomePage() {
            const currentURL = new URL(window.location.href);
            const user = this.$store.state.user;

            let workspace = null;
            if (currentURL.port !== `8079` && (this.canRead(this.Permission.WIFI.feature.WORKSPACE_LEGACY) || this.canRead(this.Permission.CUSTOMER_SERVICE))) {
                workspace = `old-wifi`;
            } else if (this.canRead(this.Permission.WIFI.feature.WORKSPACE_BETA)) {
                workspace = `wifi`;
            } else if (this.canRead(this.Permission.DIGITAL_SIGNAGE)) {
                workspace = `digital-signage`;
            } else if (this.canRead(this.Permission.LOYALTY)) {
                workspace = `loyalty`;
            } else if (this.canRead(this.Permission.NEWSLETTERS)) {
                workspace = `newsletters`;
            }

            if (!user.default_welcome_page || user.default_welcome_page === `no` || isStringEmpty(user.default_welcome_page)) {
                if (!workspace || workspace === `old-wifi`) {
                    this.loading = true;
                    window.location.href = `/vue_welcome_redirect.php`;
                    // console.warn(`redirecting to /vue_welcome_redirect.php`);
                } else {
                    this.$router.push(`/${workspace}/dashboard`);
                }
            } else {
                if (user.default_welcome_page.indexOf(`.php`) >= 0) {
                    this.loading = true;
                    window.location.href = `/` + user.default_welcome_page;
                } else if (user.default_welcome_page.indexOf(`#`) >= 0) {
                    this.$router.push(user.default_welcome_page.split(`#`).pop());
                } else {
                    // Should not happen
                    this.$swal(`Error`, `Wrong welcome page associated to your account: ${user.default_welcome_page}`, `error`);
                }
            }
        }
    }
});

export default {};
