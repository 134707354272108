<template>
    <vue-ctk-date-time-picker class="e-datetime-picker" :id="id" :value="computedValue" @input="onInput" @change="$emit('change', $event)"
                              :label="computedLabel" :range="range" :right="right" :class="{ 'inline-field': inlineField }"
                              :only-date="onlyDate" :only-time="onlyTime" :type="type"
                              :shortcut="shortcut" :custom-shortcuts="computedCustomShortcuts" :auto-close="autoClose"
                              :min-date="minDate" :max-date="maxDate" :inline="inline" :dark="dark" :overlay="overlay"
                              :no-clear-button="noClearButton" :no-buttons="noButtons" :no-button-now="noButtonNow"
                              :no-shortcuts="noShortcuts" :no-header="noHeader" :no-label="noLabel"
                              :format="computedFormat" :formatted="computedFormatted" :minute-interval="minuteInterval" :no-value-to-custom-elem="true">
        <slot v-bind="$slots"></slot>
    </vue-ctk-date-time-picker>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
    name: `e-datetime-picker`,
    components: {
        'vue-ctk-date-time-picker': VueCtkDateTimePicker
    },
    props: {
        value: {},
        formatted: {},
        format: {},
        type: {},
        label: {},
        shortcut: {},
        customShortcuts: {},
        onlyDate: {type: Boolean, default: false},
        onlyTime: {type: Boolean, default: false},
        right: {type: Boolean, default: false},
        range: {type: Boolean, default: false},
        overlay: {type: Boolean, default: false},
        inline: {type: Boolean, default: false},
        inlineField: {type: Boolean, default: false},
        dark: {type: Boolean, default: false},
        autoClose: {type: Boolean, default: false},
        noLabel: {type: Boolean, default: false},
        noHeader: {type: Boolean, default: false},
        noShortcuts: {type: Boolean, default: false},
        reportShortcuts: {type: Boolean},
        noButtons: {type: Boolean, default: false},
        noButtonNow: {type: Boolean, default: false},
        noClearButton: {type: Boolean, default: false},
        minuteInterval: {type: [Number, String], default: 15},
        minDate: {},
        maxDate: {}
    },
    computed: {
        id() {
            return Math.random().toString();
        },
        computedCustomShortcuts() {
            if (this.reportShortcuts) {
                let shortcuts = [
                    {key: `thisWeek`, label: this.tr(`This week|Cette semaine`), value: `isoWeek`},
                    {key: `lastWeek`, label: this.tr(`Last week|Semaine dern.`), value: `-isoWeek`},
                    // {key: `last7Days`, label: this.tr(`Last 7 days|7 dern. jours`), value: 7},
                    // {key: `last30Days`, label: this.tr(`Last 30 days|30 dern. jours`), value: 30},
                    {key: `thisMonth`, label: this.tr(`This month|Ce mois-ci`), value: `month`},
                    {key: `lastMonth`, label: this.tr(`Last month|Mois dernier`), value: `-month`},
                    {key: `thisYear`, label: this.tr(`This year|Cette année`), value: `year`},
                    {
                        key: `last2Years`,
                        label: this.tr(`Last 2 years|2 dern. années`),
                        value: () => {
                            return {
                                start: this.$moment().subtract(2, `years`).startOf(`year`),
                                end: this.$moment()
                            }
                        }
                    },
                    {
                        key: `last5Years`,
                        label: this.tr(`Last 5 years|5 dern. années`),
                        value: () => {
                            return {
                                start: this.$moment().subtract(5, `years`).startOf(`year`),
                                end: this.$moment()
                            }
                        }
                    },
                    {
                        key: `last10Years`,
                        label: this.tr(`Last 10 years|10 dern. années`),
                        value: () => {
                            return {
                                start: this.$moment().subtract(10, `years`).startOf(`year`),
                                end: this.$moment()
                            }
                        }
                    }
                ];

                const currentYear = new Date().getFullYear();
                for (let i = 1; i <= 10; i++) {
                    const year = currentYear - i;
                    shortcuts.push({
                        key: `year${year}`,
                        label: year,
                        value: () => {
                            return {
                                start: this.$moment(`${year}-01-01 00:00`),
                                end: this.$moment(`${year}-12-31 23:59`)
                            }
                        }
                    });
                }

                return shortcuts
            } else {
                return this.customShortcuts;
            }
        },
        computedLabel() {
            if (this.label) {
                return this.label;
            } else {
                return this.tr(`Select date|Choisissez la date`);
            }
        },
        computedValue() {
            return this.formatDate(this.value);
        },
        computedFormatted() {
            if (this.formatted)
                return this.formatted;

            if (this.range || this.onlyDate || this.type === `date`) {
                return `ll`;
            } else if (this.onlyTime || this.type === `time`) {
                return `HH:mm`
            } else {
                return `llll`;
            }
        },
        computedFormat() {
            if (this.format)
                return this.format;

            if (this.range || this.onlyDate || this.type === `date`) {
                return `YYYY-MM-DD`;
            } else if (this.onlyTime || this.type === `time`) {
                return `HH:mm:ss`;
            } else {
                return `YYYY-MM-DDTHH:mm:ss.SSSZ`;
            }
        }
    },
    methods: {
        onInput(e) {
            this.$emit(`input`, this.formatDate(e));
        },
        formatDate(value) {
            if (!value)
                return null;

            if (this.range) {
                let dates = {
                    start: null,
                    end: null
                };
                if (value.start) {
                    dates.start = this.$moment.utc(value.start).format(this.computedFormat);
                }
                if (value.end) {
                    dates.end = this.$moment.utc(value.end).format(this.computedFormat);
                }
                return dates;
            } else {
                if (this.onlyDate || this.type === `date`) {
                    return this.$moment.utc(value).format(this.computedFormat);
                } else if (this.onlyTime || this.type === `time`) {
                    if (typeof value === `object`)
                        return this.$moment.utc(value).format(this.computedFormat);
                    else
                        return this.$moment.utc(value, this.computedFormat).format(this.computedFormat);
                } else {
                    return value;
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.e-datetime-picker {
    &.inline-field {
        display: inline-block;
        vertical-align: middle;
        width: 250px;
        margin: 0;
    }

    ::v-deep .custom-button.shortcut-button {
        padding-left: 5px;
        padding-right: 5px;
    }
}
</style>
