<template>
    <div class="simple-page">
        <section class="page-container register-page">
            <div class="logo-container">
                <b-img class="logo" height="80" src="https://eyeinlivestorage.blob.core.windows.net/public/icons/eye-in-media.png" alt=""></b-img>
            </div>
            <div v-if="contract" class="main-form px-3 py-3">
                <div class="header text-center mb-3">
                    <h4>{{ contract.name }}</h4>
                    <div>
                            {{ contract.contract_data.establishment.address }}, {{ contract.contract_data.establishment.city }}
                    </div>
                    <div>
                            {{ contract.contract_data.establishment.state }}, {{ contract.contract_data.establishment.country }}, {{ contract.contract_data.establishment.postal_code }}
                    </div>
                    <h4 class="f-500 mt-3" v-tr>Agreement Summary|Résumé du contrat</h4>
                </div>

                <digital-signage-contract-overview :contract="contract"/>

                <div class="text-center mt-4">
                    <b-btn size="lg" @click="showPdf = true; pdfModalEnabled = true;">
                        <span v-tr>Review Entire Agreement</span>
                        <i class="fas fa-file-text ml-2"></i>
                    </b-btn>
                </div>
                <div class="text-center mt-3">
                    <template v-if="contract.status !== 'signed'">
                        <b-form-group>
                            <b-checkbox v-model="accept">I have Read and Accept the Entire Agreement</b-checkbox>
                        </b-form-group>
                        <b-btn @click.stop="signatureVisible = true" variant="primary" :disabled="!accept" size="lg">
                            <span v-tr>Sign Agreement|Signer le contrat</span>
                            <i class="fas fa-file-signature ml-2"></i>
                        </b-btn>
                    </template>

                    <template v-else>
                        <b-alert show v-if="contractData.contract.payment_required" variant="primary" class="mx-auto mb-3" style="width: 400px">
                            <h5 v-tr>Payment required</h5>
                            <div class="description">A payment of {{contractData.contract.payment_due_amount|smartPrice}} is required, please click on the link below if you didn't paid yet</div>
                            <b-btn block class="my-3" :href="contractData.contract.redirect_to" variant="primary">Open Payment Form</b-btn>

                            <b-img width="180" class="mt-2" center src="https://eyeinlivestorage.blob.core.windows.net/public/icons/square-credit-card-logo.png"/>
                        </b-alert>

                        <b-alert show variant="success" class="mx-auto" style="width: 400px">
                            <span v-tr>Agreement Reviewed and Signed</span>
                            <i class="fas fa-check-circle ml-2"></i>
                            <div class="f-11 mt-2 text-italic">
                                {{contract.date_signed|datetime}}
                            </div>
                        </b-alert>
                    </template>

<!--                    <div class="mt-5">-->
<!--                        <h6>Contact</h6>-->
<!--                        <a class="text-muted text-underline" href="tel:13055149829" target="_blank">+1 (305) 514-9829</a>-->
<!--                        <a class="text-muted text-underline ml-2" href="mailto:billing@eyeinmedia" target="_blank">billing@eyeinmedia.com</a>-->
<!--                    </div>-->
<!--                    <div class="mt-2">-->
<!--                        <a class="text-muted text-underline ml-2" href="https://eye-in.com/" target="_blank">Visit our Website</a>-->
<!--                    </div>-->
                </div>

                <e-iframe-dialog v-if="pdfModalEnabled" v-model="showPdf" :src="contract.pdf_url" size="lg" fill-height allow-open-external fullscreen/>

                <e-signature v-model="signatureVisible" @sign="signContract"></e-signature>
            </div>
        </section>
    </div>
</template>

<script>

import DigitalSignageContractOverview from "@/component/contracts/digital-signage-contract-overview.vue";
import EIframeDialog from "../../vue-components/components/e-iframe-dialog.vue";
import ESignature from "../../vue-components/components/e-signature.vue";
import Network from "../../vue-components/helpers/Network.js";
export default {
    name: `agreement-signature`,
    components: {EIframeDialog, DigitalSignageContractOverview, ESignature},
    data() {
        return {
            accept: false,
            contract: null,
            rewardOptions: [
                { text: this.tr(`Percent Discount|Rabais en pourcentage`), value: `percent-discount` },
                { text: this.tr(`Dollar Amount|Montant en Dollars`), value: `dollar-amount` },
                { text: this.tr(`Free Item|Article gratuit`), value: `free-item` },
                { text: this.tr(`Nothing|Rien`), value: `nothing` }
            ],
            showPdf: false,
            pdfModalEnabled: false,
            signatureVisible: false
        }
    },
    computed: {
        contractId() {
            return this.$route.params.id;
        },
        contractData() {
            return this.contract.contract_data;
        },
        rewardType() {
            if (!this.contract)
                return {};

            return this.rewardOptions.find(r => r.value === this.contract.contract_data.contract.reward_type) || {};
        }
    },
    methods: {
        loadContract() {
            return Network.get(`/api/contract/${this.contractId}`)
                .then(res => {
                    this.contract = res.data;
                })
                .catch(err => console.error(err.response.data));
        },
        signContract(base64) {
            this.showLoading(`Sending Signature`, `Please wait while we wait for the confirmation...`);
            return Network.post(`/api/contract/sign`, {
                id: this.contractId,
                signature: base64
            })
            .then(res => {
                this.contract = res.data;
                if (this.contractData.contract.redirect_to) {
                    if (this.contractData.contract.payment_required) {
                        this.showSavedMessage(`Thank you`, `A payment is required, we will redirect you to the payment form in 5 secs.`);
                    } else {
                        this.showSavedMessage(`Thank you`, `We received your signature.`);
                    }
                    setTimeout(() => {
                        window.location.href = this.contractData.contract.redirect_to;
                    }, 5000);
                } else {
                    this.showSavedMessage(`Thank you`, `You will receive your access soon`);
                }
            })
            .catch(err => {
                const message = err.response.data;

                if (message === `contract_not_found`) {
                    return this.$swal({
                        icon: `info`,
                        title: this.tr(`Contract not found|Contrat introuvable`)
                    });
                } else if (message === `contract_already_signed`) {
                    return this.$swal({
                        icon: `info`,
                        title: this.tr(`Contract already signed|Ce contrat a déjà été signé`)
                    });
                } else {
                    return this.$swal({
                        icon: `warning`,
                        title: this.tr(`Could not sign`),
                        text: this.tr(`Please try again, and if the issue persists, contact us at +1 (305) 514-9829`)
                    });
                }
            });
        }
    },
    mounted() {
        this.loadContract();
    }
}
</script>

<style scoped lang="scss">

.simple-page {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding: 50px 0;
    position: relative;
    background: #f1f5f8;

    overflow-x: hidden;
    overflow-y: auto;
}

.page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: start;
    background-color: #f1f5f8;
    width: 800px;
    margin: 0 auto;
    color: #555;
    max-width: 100%;
    min-height: 100%;
    padding: 15px 0 0;
    position: relative;
    z-index: 1;
    box-shadow: 0 0 100px rgba(black, 0.3);
    border-radius: 10px;

    .logo-container {
        text-align: center;
        flex: 1;

        .logo {
            max-width: 300px;
        }

        @media(max-width: 576px) {
            flex: 0;
            height: 100px;

            .logo {
                max-width: 200px;
            }
        }
    }

    .main-form {
        flex: 10;
    }

    .basic-link {
        font-size: 10pt;
        color: rgba(0, 0, 0, 0.8);
    }
}

@media(max-width: 576px) {
    .simple-page {
        padding: 0;

        .page-container {
            padding: 15px;
            border-radius: 0;
        }
    }
}

</style>
