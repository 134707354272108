<template>
    <vue-multiselect class="e-dealer-select" v-model="inputVal" :options="optionList" :show-labels="false" :placeholder="placeholder" :class="{ 'multiple': multiple, 'single': !multiple }"
                     :allow-empty="allowEmpty" :multiple="multiple" @input="$emit('change', $event)"></vue-multiselect>
</template>

<script>
    export default {
        name: `e-dealer-select`,
        props: {
            value: {type: String},
            placeholder: {type: String},
            allowEmpty: {type: Boolean, default: false},
            options: {},
            multiple: {type: Boolean, default: false}
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit(`input`, val);
                }
            },
            optionList() {
                return this.options ? this.options : this.dealerIds;
            },
            dealerIds() {
                return this.$store.state.dealers.map(dealer => {
                    return dealer.dealerid;
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>
