<template>
    <b-input-group append="CAD">
        <b-input type="number" v-model="price" @change="emitPrice" :min="0" step="0.01" :required="required"></b-input>
    </b-input-group>
</template>
<script>
export default {
    name: `e-money-input`,
    props: {
        value: {
            type: Number,
            required: false,
            default: 0
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            price: 0
        }
    },
    mounted() {
        this.setPrice();
    },
    watch: {
        value() {
            this.setPrice();
        }
    },
    methods: {
        setPrice() {
            if (this.value === null || this.value === undefined) {
                this.price = 0;
            } else {
                this.price = this.value / 100;
            }
        },
        emitPrice() {
            if (!this.price) {
                this.price = 0;
            }
            const emit = Math.floor(this.price * 100);
            this.price = emit / 100;
            this.$emit(`input`, emit);
        }
    }
}
</script>
<style scoped lang="scss">

</style>
