<template>
    <app-page>
        <template #page-header>
            <page-header back-button="/licenses" :name="mode !== `edit` ? `New Licence|Nouvelle license` : 'License Details|Détails de la license'|tr"
                         no-search no-secondary-bar>
                <template #search>
                    <div v-if="canWrite(Permission.LICENSE.feature.MANAGEMENT)">
                        <b-btn @click="saveLicense" variant="success" class="mr-2" :disabled="!formValid">
                            <template v-if="mode === `edit`">
                                <i class="fas fa-save mr-1"></i>
                                <span v-tr>Save|Enregistrer</span>
                            </template>
                            <template v-else>
                                <i class="fas fa-plus mr-1"></i>
                                <span v-tr>Create|Créer</span>
                            </template>
                        </b-btn>
                    </div>
                </template>
            </page-header>
        </template>

        <b-row v-if="license">
            <b-col cols="12" md="6" lg="8">
                <license-form v-model="license"/>
            </b-col>
            <b-col cols="12" md="6" lg="4" v-if="mode === `edit`">
                <b-card class="my-3" no-body>
                    <b-card-body class="package">
                        <h5 class="mb-3">
                            <span v-tr>Associated item|Équipement associé</span>
                            <i class="fas fa-link-horizontal fa-main-icon"/>
                        </h5>

                        <div v-if="license.item_id">
                            <b-media class="mb-2">
                                <template #aside>
                                    <b-img :src="icon" alt="Item icon" width="80"/>
                                </template>

                                <div class="f-500 mt-3" v-tr>{{ license.item_name }}</div>
                            </b-media>

                            <p class="mb-1"><strong v-tr>OS/Manufacturer: |OS/Fabriquant : </strong>{{ license.os }}</p>
                            <p class="mb-1"><strong v-tr>Last contact: |Dernier contact : </strong>{{ license.last_contact|datetime }}</p>

                            <b-btn @click="unlinkLicense" variant="warning" block class="mt-2 text-white">
                                <i class="fas fa-unlink"></i>
                                <span v-tr> Unlink| Dissocier</span>
                            </b-btn>

                            <hr>

                            <div :class="{'text-danger': license.slot_items.length > license.slots}">
                                <strong>{{ license.slot_name|tr }}:</strong>
                                {{ license.slot_items.length }} / {{ license.slots }}
                                <b-list-group class="mt-2">
                                    <b-list-group-item v-for="item of license.slot_items" :key="item.id">{{ item.name }}</b-list-group-item>
                                </b-list-group>
                            </div>
                        </div>
                        <div v-else class="text-center">
                            <img :src="emptyIcon" alt="No item icon" class="w-25">
                            <p v-tr>No item associated|Pas d'équipement associé</p>
                        </div>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
        <div class="text-right" v-if="canWrite(Permission.LICENSE.feature.MANAGEMENT)">
            <e-button-confirm @confirmed="deleteLicense" variant="danger" v-if="canAdminEyeInWireless() && mode === `edit`">
                <i class="fas fa-trash mr-1"></i>
                <span v-tr>Delete|Supprimer</span>
            </e-button-confirm>
            <b-btn @click="saveLicense" variant="success" class="ml-2" :disabled="!formValid">
                <template v-if="mode === `edit`">
                    <i class="fas fa-save mr-1"></i>
                    <span v-tr>Save|Enregistrer</span>
                </template>
                <template v-else>
                    <i class="fas fa-plus mr-1"></i>
                    <span v-tr>Create|Créer</span>
                </template>
            </b-btn>
        </div>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import LicenseHelper from "../helpers/LicenseHelper.js";
import LicenseForm from "@/component/license-form.vue";

export default {
    name: `license`,
    components: {LicenseForm, EButtonConfirm},
    data() {
        return {
            license: null,
            mode: `edit`,
            adminMode: false,
            LicenseHelper
        }
    },
    computed: {
        formValid() {
            return this.license && this.license.dealerid
        },
        emptyIcon() {
            switch (this.license.item_type) {
                case `router`:
                    return getPublicIcon(`router-warning`);
                case `player`:
                default:
                    return getPublicIcon(`player-offline`);
            }
        },
        icon() {
            switch (this.license.item_type) {
                case `router`:
                    return getPublicIcon(`router`);
                case `player`:
                default:
                    return getPublicIcon(`player`);
            }
        }
    },
    async activated() {
        this.adminMode = false;
        this.mode = this.$route.params.id === `create` ? `create` : `edit`;

        await this.getLicense();
    },
    methods: {
        async getLicense() {
            if (this.mode === `create`) {
                this.license = {
                    id: null,
                    dealerid: this.$route.query.dealer || this.$store.state.user.dealerid,
                    category: this.$route.query.category || `wifi`,
                    managed_by: `eye-in`,
                    renew_automatically: true
                }
            } else {
                const resp = await Network.get(`/api/license/${this.$route.params.id}`);
                this.license = resp.data;
            }
        },
        saveLicense() {
            if (this.license.discount_does_not_expire) {
                this.license.dicount_expiration = null;
            }

            if (this.mode === `create`) {
                Network.post(`/api/license/create`, this.license)
                    .then(resp => {
                        if (window.self !== window.top) {
                            window.parent.postMessage({name: `license-created`, data: resp.data.id}, `*`);
                        } else {
                            this.showSavedMessage(`License created|Licence créée`);
                            this.$router.push(`/license/${resp.data.id}`);
                        }
                    })
            } else {
                Network.patch(`/api/license/edit`, this.license)
                    .then(() => {
                        if (window.self !== window.top) {
                            window.parent.postMessage({name: `license-edited`, data: this.license.id}, `*`);
                        } else {
                            this.showSavedMessage(`License saved|Licence enregistrée`);
                            this.getLicense();
                        }
                    })
            }
        },
        deleteLicense() {
            Network.delete(`/api/license/${this.license.id}`)
                .then(() => {
                    this.showSavedMessage(`License deleted|Licence supprimée`);
                    this.$router.push(`/licenses`);
                })
        },
        unlinkLicense() {
            Network.patch(`/api/license/unlink`, {id: this.license.id})
                .then(() => {
                    this.showSavedMessage(`Unlink successful|Dissocié avec succès`);
                    this.getLicense()
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
