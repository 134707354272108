export default {
    local: {
        VUE_APP_ENV: `local`,
        VUE_APP_URL: `http://localhost:5423`,
        VUE_APP_API_URL: `http://localhost:5423`,
        VUE_APP_SYSTEM_API_URL: `http://localhost:8100`,
        VUE_APP_LOGIN_URL: `https://dev.login.eyeinmedia.com` ,
        // VUE_APP_LOGIN_URL: `http://localhost:7446`// for local login tests
    },
    dev: {
        VUE_APP_ENV: `dev`,
        VUE_APP_URL: `https://dev.licensing.eyein.media`,
        VUE_APP_API_URL: `https://dev.licensing.eyein.media`,
        VUE_APP_SYSTEM_API_URL: `https://dev.api.eyein.media`,
        VUE_APP_LOGIN_URL: `https://dev.login.eyeinmedia.com`
    },
    demo: {
        VUE_APP_ENV: `demo`,
        VUE_APP_URL: `https://licensing.eyein.media`,
        VUE_APP_API_URL: `https://licensing.eyein.media`,
        VUE_APP_SYSTEM_API_URL: `https://demo.api.eyein.media`,
        VUE_APP_LOGIN_URL: `https://demo.login.eyeinmedia.com`
    },
    live: {
        VUE_APP_ENV: `live`,
        VUE_APP_URL: `https://licensing.eyein.media`,
        VUE_APP_API_URL: `https://licensing.eyein.media`,
        VUE_APP_SYSTEM_API_URL: `https://api.eyein.media`,
        VUE_APP_LOGIN_URL: `https://login.eyeinmedia.com`
    }
};
