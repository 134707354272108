<template>
    <app-page no-container>
        <template #page-header>
            <page-header name="Licenses" :loading="loading" no-search no-secondary-bar>
                <template #search>
                    <b-form-radio-group v-model="categoryFilter" :options="LicenseHelper.getCategories()" buttons></b-form-radio-group>

                    <b-btn variant="warning" v-if="canAdminEyeInWireless() && selectedLicenses.length > 0" v-b-modal:edit-modal>
                        <i class="fas fa-edit mr-1"></i>
                        <span v-tr>Multi edit|Modification multi</span>
                    </b-btn>

                    <b-btn :to="`/license/create?category=${categoryFilter}`" variant="primary" v-if="canAdminEyeInWireless()">
                        <i class="fas fa-plus mr-1"></i>
                        <span v-tr>Create a license|Créer une licence</span>
                    </b-btn>
                </template>
            </page-header>
        </template>

        <e-table-helper :fields="licensesFields" :items="filteredLicenses" class="fullscreen w-100" :default-per-page="25"
                        :selectable="canAdminEyeInWireless()" selected-variant="primary" @row-selected="onRowSelected" compact fullscreen>
            <template #selected="data">
                <e-check-mark :value="selectedLicenses.indexOf(data.item) > -1"/>
            </template>
            <template #dealerid="data">
                {{ data.value }}
            </template>
            <template #item_name="data">
                <router-link class="text-underline" :to="'/license/' + data.item.id">{{ data.value ? data.value : tr(`(not assigned)|(pas assignée)`) }}</router-link>
            </template>
            <template #item_type="data">
                <b-img class="icon" :src="LicenseHelper.getItemType(data.value).icon"/>
                {{ LicenseHelper.getItemTypeText(data.value) }}
            </template>
            <template #package_name="data">
                {{ data.value|tr }}
            </template>
            <template #price_per_cycle="data">
                {{ data.value|priceInCents }}
            </template>
            <template #date_creation="data">
                {{ data.value|date }}
            </template>
            <template #date_last_renew="data">
                {{ data.value|date }}
            </template>
            <template #date_expiration="data">
                {{ data.value|date }}
            </template>
            <template #id="data">
                <b-btn class="text-underline" :to="'/license/' + data.item.id" :title="data.value"><i class="fas fa-arrow-up-right-from-square"></i></b-btn>
            </template>
        </e-table-helper>

        <b-modal id="edit-modal" @ok="multiEdit">
            <b-form-group>
                <template #label>
                    <span v-tr>Package|Package</span>&nbsp;
                    <i id="license-description" class="fas fa-info-circle"></i>
                </template>

                <b-select v-model="licenseMultiEdited.package_id">
                    <option v-for="licensePackage in availablePackages" :value="licensePackage.id">{{ licensePackage.name|tr }}</option>
                </b-select>
            </b-form-group>

            <b-popover target="license-description" triggers="hover" v-if="licenseMultiEdited.package">
                <template #title><span v-tr>Package description|Description du package</span></template>
                {{ licenseMultiEdited.package.description|tr }}
            </b-popover>

            <b-form-group label="Slots">
                <b-input v-model.number="licenseMultiEdited.slots" type="number" min="0" required></b-input>
            </b-form-group>

            <b-form-group :label="tr('Price|Prix')">
                <e-money-input v-model="licenseMultiEdited.price_per_cycle" required></e-money-input>
            </b-form-group>

            <b-form-group :label="'Discount|Reduction'|tr">
                <b-input-group prepend="-" append="%">
                    <b-input v-model.number="licenseMultiEdited.discount_percent" :min="0" :max="100" required></b-input>
                </b-input-group>
            </b-form-group>

            <b-form-group>
                <template #label>
                    <span v-tr>Expiration du discount|Date d'expiration de la réduction</span>
                    <b-form-checkbox v-model="licenseMultiEdited.discount_does_not_expire" switch inlinen>
                        <span v-tr>Discount does not expire|La réduction n'expire jamais</span>
                    </b-form-checkbox>
                </template>
                <e-datetime-picker v-if="!licenseMultiEdited.discount_does_not_expire" v-model="licenseMultiEdited.discount_expiration"></e-datetime-picker>
            </b-form-group>

            <b-form-group :label="'Discount reason|Raison de la réduction'|tr">
                <b-input v-model="licenseMultiEdited.discount_reason"></b-input>
            </b-form-group>

            <b-form-group :label="'Expiration date|Date d\'expiration'|tr">
                <e-datetime-picker v-model="licenseMultiEdited.date_expiration"></e-datetime-picker>
            </b-form-group>
        </b-modal>
    </app-page>
</template>

<script>
import ECheckMark from "../../vue-components/components/e-check-mark.vue";
import ETableHelper from "../../vue-components/components/e-table-helper.vue";
import Network from "../../vue-components/helpers/Network.js";
import LicenseHelper from "@/helpers/LicenseHelper.js";
import EDatetimePicker from "../../vue-components/components/e-datetime-picker.vue";
import EMoneyInput from "../../vue-components/components/e-money-input.vue";

export default {
    name: `home`,
    components: {ECheckMark, EMoneyInput, EDatetimePicker, ETableHelper},
    data() {
        return {
            loading: false,
            licenses: [],
            licensePackages: [],
            licensesFields: [
                {key: `selected`, label: this.tr(`#|#`), sortable: false},
                {key: `item_type`, label: this.tr(`Item type|Type d'équipement`), sortable: true},
                {key: `dealerid`, label: this.tr(`Venue|Etablissement`), sortable: true},
                {key: `item_name`, label: this.tr(`Assigned to|Assigné à`), sortable: true},
                {key: `package_name`, label: this.tr(`Package|Package`), sortable: true},
                {key: `slots`, label: this.tr(`Slots|Slots`), sortable: true},
                {key: `price_per_cycle`, label: this.tr(`Price|Prix`), sortable: true},
                {key: `date_creation`, label: this.tr(`Creation date|Date d'ajout`), sortable: true},
                {key: `date_last_renew`, label: this.tr(`Last Renewal|Dernier renouvellement`), sortable: true},
                {key: `date_expiration`, label: this.tr(`Expiration date|Date d'expiration`), sortable: true},
                {key: `id`, label: this.tr(`Open|Ouvrir`), sortable: false},
            ],
            selectedLicense: {},
            selectedLicenses: [],
            licenseMultiEdited: {},
            categoryFilter: `digital-signage`,
            LicenseHelper
        }
    },
    computed: {
        filteredLicenses() {
            return this.licenses.filter(l => l.category === this.categoryFilter)
        },
        availablePackages() {
            if (!this.licenseMultiEdited.category) {
                return this.licensePackages;
            }
            return this.licensePackages.filter(p => p.category === this.licenseMultiEdited.category)
        }
    },
    mounted() {
        this.getLicenses()
        this.getPackages();
    },
    activated() {
        this.getLicenses()
    },
    methods: {
        async getLicenses() {
            this.loading = true;
            const resp = await Network.get(`/api/licenses`);
            this.licenses = resp.data;
            this.loading = false;
        },
        async getPackages() {
            const resp = await Network.get(`/api/packages`);
            this.licensePackages = resp.data;
        },
        onRowSelected(items) {
            this.selectedLicenses = items;

            if (items.length === 0) {
                return;
            }

            this.licenseMultiEdited.ids = items.map(i => i.id);
        },
        multiEdit() {
            Network.post(`/api/license/multi-edit`, this.licenseMultiEdited)
                .then(() => {
                    this.getLicenses();
                })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
