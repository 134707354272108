<template>
    <app-page>
        <template #page-header>
            <page-header name="Packages" :loading="loading" no-search no-secondary-bar>
                <template #search>
                    <b-form-radio-group v-model="categoryFilter" :options="LicenseHelper.getCategories()" buttons></b-form-radio-group>

                    <b-btn to="/package/create" variant="primary">
                        <i class="fas fa-plus mr-1"></i>
                        <span v-tr>Create a package|Créer un package</span>
                    </b-btn>
                </template>
            </page-header>
        </template>

        <e-table-helper :fields="licensePackagesFields" :items="filteredPackages" @item-opened="openPackage" class="w-100" fullscreen :default-per-page="25">
            <template #name="data">
                <router-link class="text-underline" :to="'/package/' + data.item.id">{{ data.value|tr }}</router-link>
            </template>
            <template #license_type="data">
                <b-img class="icon" :src="LicenseHelper.getItemType(data.value).icon"/>
                {{ LicenseHelper.getItemTypeText(data.value) }}
            </template>
            <template #price="data">
                {{ data.value|priceInCents }}
            </template>
            <template #default_discount_months_duration="data">
                {{ data.value }} <span v-tr>months|mois</span>
            </template>
        </e-table-helper>
    </app-page>
</template>

<script>
import ETableHelper from "../../vue-components/components/e-table-helper.vue";
import Network from "../../vue-components/helpers/Network.js";
import LicenseHelper from "@/helpers/LicenseHelper.js";

export default {
    name: `home`,
    components: {ETableHelper},
    data() {
        return {
            loading: false,
            licensePackages: [],
            licensePackagesFields: [
                {key: `license_type`, label: this.tr(`License type|Type de licence`), sortable: true},
                {key: `name`, label: this.tr(`Name|Nom`), sortable: true},
                {key: `default_slots`, label: this.tr(`Slots|Slots`), sortable: true},
                {key: `price`, label: this.tr(`Price|Prix`), sortable: true},
                {key: `default_discount`, label: this.tr(`Discount|Réduction`), sortable: true},
                {key: `default_discount_months_duration`, label: this.tr(`Discount duration|Durée de réduction`), sortable: true},
            ],
            selectedLicense: {},
            categoryFilter: `digital-signage`,
            LicenseHelper
        }
    },

    computed: {
        filteredPackages() {
            return this.licensePackages.filter(l => l.category === this.categoryFilter)
        }
    },
    activated() {
        this.getPackages()
    },
    methods: {
        getPackages() {
            this.loading = true;
            Network.get(`/api/packages`)
                .then(resp => {
                    this.loading = false;
                    this.licensePackages = resp.data;
                })
        },
        openPackage(licensePackage) {
            this.$router.push(`/package/${licensePackage.id}`)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
