<template>
    <b-row v-if="license">
        <b-col cols="12" md="6">
            <b-card class="my-3 simple">
                <template #header>
                    <h5 v-tr>License Info|Informations de la license</h5>
                    <i class="fas fa-info-circle fa-main-icon"/>
                </template>

                <b-form-group :label="'Dealer|Client'|tr" v-if="!hideDealer">
                    <e-dealer-select v-model="license.dealerid" required></e-dealer-select>
                </b-form-group>

                <hr>

                <b-form-group v-if="mode === `create`" :label="'Category|Catégorie'|tr">
                    <b-input-group>
                        <b-input-group-text>
                            <i class="fas fa-fw" :class="LicenseHelper.getCategoryByValue(license.category).glyph"></i>
                        </b-input-group-text>
                        <b-select v-model="license.category" @change="categoryChanged">
                            <option v-for="category in LicenseHelper.getCategories()" :value="category.value">{{ category.text }}</option>
                        </b-select>
                    </b-input-group>
                </b-form-group>

                <b-form-group>
                    <template #label>
                        <span v-tr>Package|Package</span>&nbsp;
                        <i id="license-description" class="fas fa-info-circle"></i>
                    </template>

                    <b-input-group>
                        <b-input-group-text>
                            <i class="fas fa-fw" :class="LicenseHelper.getItemType(license.package.license_type).glyph"></i>
                        </b-input-group-text>
                        <b-select v-model="license.package_id" @change="packageChanged">
                            <option v-for="licensePackage in availablePackages" :value="licensePackage.id">{{ licensePackage.name|tr }}</option>
                        </b-select>
                    </b-input-group>
                </b-form-group>

                <b-popover target="license-description" triggers="hover" v-if="license.package">
                    <template #title><span v-tr>Package description|Description du package</span></template>
                    {{license.package.description|tr}}
                </b-popover>

                <b-form-group :label="license.package.slot_name|tr" v-if="adminMode">
                    <b-input v-model.number="license.slots" type="number" min="0" required></b-input>
                </b-form-group>

                <p><strong>{{ license.slots }} {{ license.package.slot_name|tr }}</strong> <span v-tr>can be associated|peuvent être associé(e)s</span></p>

                <template #footer v-if="canAdminEyeInWireless()">
                    <div class="text-right">
                        <b-btn size="sm" variant="warning" @click="adminMode = !adminMode"><i class="fas fa-edit"></i> Admin Mode</b-btn>
                    </div>
                </template>
            </b-card>
        </b-col>
        <b-col cols="12" md="6">
            <b-card class="my-3 simple">
                <template #header>
                    <h5 v-tr>Billing information|Informations de paiement</h5>
                    <i class="fas fa-money-bill fa-main-icon"/>
                </template>

                <template v-if="adminMode">
                    <b-form-group :label="tr('Price|Prix') + ' (' + billingCycleText + ')'">
                        <e-money-input v-model="license.price_per_cycle" required></e-money-input>
                    </b-form-group>

                    <b-form-group :label="'Expiration date|Date d\'expiration'|tr">
                        <e-datetime-picker v-model="license.date_expiration"></e-datetime-picker>
                    </b-form-group>
                </template>
                <template v-else>
                    <p>
                        <strong><span v-tr>Price|Prix</span> ({{ billingCycleText }}):</strong> {{ license.price_per_cycle | priceInCents }}
                        <span v-if="license.discount_percent" class="text-success">(-{{ license.discount_percent }}%<template v-if="license.discount_expiration"><span v-tr> until| jusqu'au</span> {{ license.discount_expiration|date }}</template>)</span>
                    </p>

                    <p><strong v-tr>Expiration date:|Date d'expiration:</strong> {{ license.date_expiration|datetime }}</p>
                </template>

                <b-form-checkbox v-model="license.renew_automatically" v-if="license.managed_by === `dealer` || canAdminEyeInWireless()" switch>
                    <span v-tr>Auto renew|Renouvellement automatique</span>
                </b-form-checkbox>
            </b-card>
            <b-card class="my-3 simple" v-if="adminMode">
                <template #header>
                    <h5 v-tr>Discount|Réduction</h5>
                    <i class="fas fa-badge-dollar fa-main-icon"/>
                </template>

                <b-form-group :label="'Discount Value|Valeur de la réduction'|tr">
                    <b-input-group prepend="-" append="%">
                        <b-input v-model.number="license.discount_percent" :min="0" :max="100" required></b-input>
                    </b-input-group>
                </b-form-group>

                <b-form-group>
                    <template #label>
                        <span v-tr>Expiration du discount|Date d'expiration de la réduction</span>
                    </template>
                    <e-datetime-picker v-if="!license.discount_does_not_expire" v-model="license.discount_expiration"></e-datetime-picker>

                    <b-form-checkbox class="mt-2" v-model="license.discount_does_not_expire" switch>
                        <span v-tr>Discount does not expire|La réduction n'expire jamais</span>
                    </b-form-checkbox>
                </b-form-group>

                <b-form-group :label="'Discount reason|Raison de la réduction'|tr">
                    <b-input v-model="license.discount_reason"></b-input>
                </b-form-group>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import EDealerSelect from "../../vue-components/components/e-dealer-select.vue";
import EDatetimePicker from "../../vue-components/components/e-datetime-picker.vue";
import EMoneyInput from "../../vue-components/components/e-money-input.vue";
import moment from "moment";
import LicenseHelper from "@/helpers/LicenseHelper.js";
import Network from "../../vue-components/helpers/Network.js";

export default {
    name: `license-form`,
    components: {EMoneyInput, EDatetimePicker, EDealerSelect},
    props: {
        value: {type: Object, required: true},
        hideDealer: {type: Boolean, default: false}
    },
    data() {
        return {
            license: null,
            licensePackages: [],
            mode: `edit`,
            adminMode: false,
            LicenseHelper
        }
    },
    computed: {
        billingCycleText() {
            switch (this.license.billing_cycle) {
                case `weekly`:
                    return this.tr(`Weekly|Hebdomadaire`);
                case `quarterly`:
                    return this.tr(`Quarterly|Trimestriel`);
                case `yearly`:
                    return this.tr(`Yearly|Annuel`);
                case `monthly`:
                default:
                    return this.tr(`Monthly|Mensuel`);
            }
        },
        availablePackages() {
            return this.licensePackages.filter(p => p.category === this.license.category)
        }
    },
    async mounted() {
        await this.getPackages();

        this.mode = this.$route.params.id === `create` ? `create` : `edit`;

        this.license = this.value;

        if (!this.license.package_id) {
            this.license.package = this.getLicensePackage();
            this.license.package_id = this.license.package.id;
            this.license.price_per_cycle = this.license.package.price_per_cycle;
            this.license.slots = this.license.package.default_slots;
            this.license.discount_percent = this.license.package.default_discount;
            this.license.discount_expiration = moment().add(this.license.package.default_discount_months_duration, `months`);
            this.license.date_expiration = moment().add(this.license.package.license_months_duration, `months`);
        } else {
            this.license.package = this.getLicensePackage();
            this.license.item_type = this.license.package.license_type;
        }
    },
    methods: {
        categoryChanged() {
            this.license.package_id = this.licensePackages.find(p => p.category === this.license.category).id;
            this.packageChanged();
        },
        packageChanged() {
            this.license.package = this.getLicensePackage();
            this.license.item_type = this.license.package.license_type;
            this.license.slots = this.license.package.default_slots;
            this.license.price_per_cycle = this.license.package.price_per_cycle;
            this.license.discount_percent = this.license.package.default_discount;
            this.license.date_expiration = moment().add(this.license.package.license_months_duration, `months`);

            this.$forceUpdate();
        },
        getLicensePackage() {
            if (!this.licensePackages) {
                return {}
            }

            let licensePackage = null;
            if (!this.license) {
                if (this.$route.query.category) {
                    if (this.$route.query.type) {
                        licensePackage = this.licensePackages.find(p => p.category === this.$route.query.category && p.license_type === this.$route.query.type);
                    } else {
                        licensePackage = this.licensePackages.find(p => p.category === this.$route.query.category);
                    }
                }

                if (licensePackage) {
                    return licensePackage;
                } else {
                    return this.licensePackages[0];
                }
            }

            licensePackage = this.licensePackages.find(p => p.id === this.license.package_id);
            if (licensePackage) {
                return licensePackage;
            } else {
                return this.licensePackages[0];
            }
        },
        async getPackages() {
            const resp = await Network.get(`/api/packages`);
            this.licensePackages = resp.data;
        }
    }
}
</script>

<style scoped lang="scss">
.license {
    text-align: left;
}
</style>
